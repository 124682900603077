export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('loading', {
    mounted(el, { value }) {
      el.classList.toggle('is-loading', value);
    },
    updated(el, { value }) {
      el.classList.toggle('is-loading', value);
    },
  });
});
