import DOMPurify from 'isomorphic-dompurify';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('purify', {
    mounted(el, { value }) {
      el.innerHTML = DOMPurify.sanitize(value);
    },
    updated(el, { value }) {
      el.innerHTML = DOMPurify.sanitize(value);
    },
  });
});
