import { default as indexoTDmuUcfKKMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/[...slug]/index.vue?macro=true";
import { default as aboutANci8QLWCeMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/about.vue?macro=true";
import { default as editXCCsCCKHTeMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/[id]/edit.vue?macro=true";
import { default as indexfmTd8vCV60Meta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/index.vue?macro=true";
import { default as newja6GBM3osfMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/new.vue?macro=true";
import { default as emailYnISMHfN7zMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/email.vue?macro=true";
import { default as indexOK42HqobyCMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/index.vue?macro=true";
import { default as password3x1uiD6tFlMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/password.vue?macro=true";
import { default as indexmAGHTrRPRrMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/index.vue?macro=true";
import { default as _91id_93whgoC8kXUNMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/[id].vue?macro=true";
import { default as indexpxyOzqPWaCMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/index.vue?macro=true";
import { default as wishlistlcJ3wPRpEnMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/wishlist.vue?macro=true";
import { default as account3i741lVXocMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account.vue?macro=true";
import { default as recovernHFi37FkiWMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/auth/recover.vue?macro=true";
import { default as _91slug_93btCz1tHoHtMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/brands/[slug].vue?macro=true";
import { default as indexwWXv9xIphfMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/brands/index.vue?macro=true";
import { default as cart3oxUfLgPWcMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/cart.vue?macro=true";
import { default as guest_45shippingJfdfAlI3MtMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/checkout/guest-shipping.vue?macro=true";
import { default as pay7gbg4iIORVMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/checkout/pay.vue?macro=true";
import { default as shippingamwTM6LIOtMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/checkout/shipping.vue?macro=true";
import { default as contactY5xGQFpLA1Meta } from "/builds/robustastudio/mazaya/pancho-v3/pages/contact.vue?macro=true";
import { default as dealsdl60iV8DcGMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/deals.vue?macro=true";
import { default as error0BBwsXbIzKMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/error.vue?macro=true";
import { default as _91slug_93AY90gW80cyMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/faqs/[slug].vue?macro=true";
import { default as index1XVpjyXhLBMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/faqs/index.vue?macro=true";
import { default as successC0zgbFZnmPMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/guest-order/[id]/success.vue?macro=true";
import { default as health_45checkTlkuK6TcmlMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/health-check.vue?macro=true";
import { default as indexBZf9Pq479YMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/index.vue?macro=true";
import { default as loginYiO4cy7hYZMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/login.vue?macro=true";
import { default as new_45inRtOgGgKEeNMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/new-in.vue?macro=true";
import { default as create_45reviewXArxgYVa0zMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/create-review.vue?macro=true";
import { default as success8TZQ5BBNkkMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/success.vue?macro=true";
import { default as privacy_45policy7FYRwNEmseMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/privacy-policy.vue?macro=true";
import { default as registerg6i5Q7lrNNMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/register.vue?macro=true";
import { default as _91name_93q9conYqD0MMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/search/[name].vue?macro=true";
import { default as searchC7zDP9mhQxMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/search.vue?macro=true";
import { default as terms_45and_45conditionsILtS7o3dXJMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/terms-and-conditions.vue?macro=true";
import { default as testDS5IArkatQMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/test.vue?macro=true";
export default [
  {
    name: "slug___ar",
    path: "/ar/:slug(.*)*",
    meta: indexoTDmuUcfKKMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: indexoTDmuUcfKKMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "about___ar",
    path: "/ar/about",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/about.vue").then(m => m.default || m)
  },
  {
    name: account3i741lVXocMeta?.name,
    path: "/ar/account",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-id-edit___ar",
    path: "addresses/:id()/edit",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___ar",
    path: "addresses",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-new___ar",
    path: "addresses/new",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-email___ar",
    path: "edit/email",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "account-edit___ar",
    path: "edit",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-password___ar",
    path: "edit/password",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: "account___ar",
    path: "",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___ar",
    path: "orders/:id()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar",
    path: "orders",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar",
    path: "wishlist",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: account3i741lVXocMeta?.name,
    path: "/en/account",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-id-edit___en",
    path: "addresses/:id()/edit",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___en",
    path: "addresses",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-new___en",
    path: "addresses/new",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-email___en",
    path: "edit/email",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "account-edit___en",
    path: "edit",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-password___en",
    path: "edit/password",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___en",
    path: "orders/:id()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en",
    path: "orders",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en",
    path: "wishlist",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-recover___ar",
    path: "/ar/auth/recover",
    meta: recovernHFi37FkiWMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: "auth-recover___en",
    path: "/en/auth/recover",
    meta: recovernHFi37FkiWMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: "brands-slug___ar",
    path: "/ar/brands/:slug()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands-slug___en",
    path: "/en/brands/:slug()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands___ar",
    path: "/ar/brands",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___en",
    path: "/en/brands",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___ar",
    path: "/ar/cart",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-guest-shipping___ar",
    path: "/ar/checkout/guest-shipping",
    meta: guest_45shippingJfdfAlI3MtMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-guest-shipping___en",
    path: "/en/checkout/guest-shipping",
    meta: guest_45shippingJfdfAlI3MtMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pay___ar",
    path: "/ar/checkout/pay",
    meta: pay7gbg4iIORVMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pay___en",
    path: "/en/checkout/pay",
    meta: pay7gbg4iIORVMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-shipping___ar",
    path: "/ar/checkout/shipping",
    meta: shippingamwTM6LIOtMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-shipping___en",
    path: "/en/checkout/shipping",
    meta: shippingamwTM6LIOtMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "deals___ar",
    path: "/ar/deals",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: "deals___en",
    path: "/en/deals",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: "error___ar",
    path: "/ar/error",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "faqs-slug___ar",
    path: "/ar/faqs/:slug()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faqs-slug___en",
    path: "/en/faqs/:slug()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faqs___ar",
    path: "/ar/faqs",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "faqs___en",
    path: "/en/faqs",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "guest-order-id-success___ar",
    path: "/ar/guest-order/:id()/success",
    meta: successC0zgbFZnmPMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "guest-order-id-success___en",
    path: "/en/guest-order/:id()/success",
    meta: successC0zgbFZnmPMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "health-check___ar",
    path: "/ar/health-check",
    meta: health_45checkTlkuK6TcmlMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: "health-check___en",
    path: "/en/health-check",
    meta: health_45checkTlkuK6TcmlMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar",
    path: "/ar/login",
    meta: loginYiO4cy7hYZMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginYiO4cy7hYZMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "new-in___ar",
    path: "/ar/new-in",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: "new-in___en",
    path: "/en/new-in",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-create-review___ar",
    path: "/ar/orders/:id()/create-review",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-create-review___en",
    path: "/en/orders/:id()/create-review",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-success___ar",
    path: "/ar/orders/:id()/success",
    meta: success8TZQ5BBNkkMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-success___en",
    path: "/en/orders/:id()/success",
    meta: success8TZQ5BBNkkMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar",
    path: "/ar/privacy-policy",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register___ar",
    path: "/ar/register",
    meta: registerg6i5Q7lrNNMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: registerg6i5Q7lrNNMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "search___ar",
    path: "/ar/search",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-name___ar",
    path: ":name()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-name___en",
    path: ":name()",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "terms-and-conditions___ar",
    path: "/ar/terms-and-conditions",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "test___ar",
    path: "/ar/test",
    meta: testDS5IArkatQMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___en",
    path: "/en/test",
    meta: testDS5IArkatQMeta || {},
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/test.vue").then(m => m.default || m)
  }
]