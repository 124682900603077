import { defaultPlugins, useClient } from 'villus';
/**
 *
 * @param Remove this function when user expiration is implemented
 */
export function mapStoreLocale(locale: 'en' | 'ar') {
  switch (locale) {
    case 'en':
      return 'default';
    case 'ar':
      return 'ar_EG';
    default:
      return 'default';
  }
}

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook('vue:setup', () => {
    const { defineAliases } = useBottomSheet();
    const { setOrderStatusMapper } = useConfigOrderStatusMapper();

    const cart = useCookie('cart');
    const route = useRoute();
    const isSecured = useCookie('is-secured');
    const locale = route.path.split('/')?.[1] || 'en';
    const {
      public: { apiUrl },
    } = useRuntimeConfig();
    useClient({
      url: apiUrl,

      use: [
        sourceMetaDataPlugin(),
        varnishPlugin(),
        localePlugin(locale, mapStoreLocale as any),
        /* userExpiration(), */ ...defaultPlugins(),
      ],
    });
    clearCart();
    useAuth();
    useStoreConfig();
    useSetupCart({
      cart: cart.value,
      'is-secured': isSecured.value,
    });
    useTrackingHandlers();

    setOrderStatusMapper({
      processing: ['pending', 'processing', 'pending_payment', 'payment_review', 'pending_paypal', 'paypal_reversed'],
      shipping: ['STATE_OPEN', 'closed'],
      canceled: ['canceled', 'paypay_canceled_reversal'],
      delivered: ['complete'],
    });

    defineAliases({
      searchDialog: defineAsyncComponent(() => import('../components/BottomSheets/SearchDialog.vue')),
    });
  });
});
