export function onDomLoaded() {
  const body = document.body;
  const html = document.documentElement;

  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

  document.documentElement.style.setProperty('--scrollbar-width', scrollBarWidth + 'px');

  document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');

  // 1166 px is the large screen container width

  document.documentElement.style.setProperty(
    '--window-edge-space',
    Math.max(0, Math.min((window.innerWidth - 1166) / 2, 100)) + 'px',
  );

  document.documentElement.style.setProperty(
    '--negative-window-edge-space',
    -1 * Math.max(0, Math.min((window.innerWidth - 1166) / 2, 100)) + 'px',
  );

  document.documentElement.style.setProperty(
    '--page-height',
    Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 'px',
  );

  document.documentElement.style.setProperty(
    '--screen-height',
    Math.max(body.offsetHeight, html.clientHeight, html.offsetHeight) + 'px',
  );
}
