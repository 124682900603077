import revive_payload_client_HYzaAPUfHi from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fkTizB1L7i from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZgiYxrvKhX from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Ys7zIqpiKe from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ftcZCZMpzC from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kS4ogi664b from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1stYx4QVkb from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/robustastudio/mazaya/pancho-v3/.nuxt/components.plugin.mjs";
import prefetch_client_1ZYjLvS5Tr from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ioredis@5_6ztxzrwxpi3omdw6jn36co6umq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_ceIcnM0aap from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_sfp3vrrhmnnc2pezyesjsl2eve/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_8NvTp2gepi from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_sfp3vrrhmnnc2pezyesjsl2eve/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_32B1TYVd89 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_vJMX8vIrJ6 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.5.4/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import gtm_client_Ezss0m4I6M from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@robustastudio+e-commerce@1.42.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_t_mknby3ta2gegvrldcrnpjublzu/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/gtm.client.mjs";
import ssr_caching_Ge90KIODX0 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@robustastudio+e-commerce@1.42.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_t_mknby3ta2gegvrldcrnpjublzu/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/ssr-caching.mjs";
import i18n_oNyGlAf6A5 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.3_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import css_global_vars_client_8R4IaJFvRf from "/builds/robustastudio/mazaya/pancho-v3/plugins/css-global-vars.client.ts";
import loading_GxQ2Z9Goxa from "/builds/robustastudio/mazaya/pancho-v3/plugins/loading.ts";
import purify_html_Hz9hnAad5c from "/builds/robustastudio/mazaya/pancho-v3/plugins/purify-html.ts";
import setup_0lDNjxjaWh from "/builds/robustastudio/mazaya/pancho-v3/plugins/setup.ts";
export default [
  revive_payload_client_HYzaAPUfHi,
  unhead_fkTizB1L7i,
  router_ZgiYxrvKhX,
  payload_client_Ys7zIqpiKe,
  navigation_repaint_client_ftcZCZMpzC,
  check_outdated_build_client_kS4ogi664b,
  chunk_reload_client_1stYx4QVkb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1ZYjLvS5Tr,
  pwa_icons_ceIcnM0aap,
  pwa_client_8NvTp2gepi,
  nuxt_plugin_32B1TYVd89,
  plugin_vJMX8vIrJ6,
  gtm_client_Ezss0m4I6M,
  ssr_caching_Ge90KIODX0,
  i18n_oNyGlAf6A5,
  css_global_vars_client_8R4IaJFvRf,
  loading_GxQ2Z9Goxa,
  purify_html_Hz9hnAad5c,
  setup_0lDNjxjaWh
]